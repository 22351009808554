import { useMemo, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { GridColDef, GridRowModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LoadingButton } from "@mui/lab";
import { useSetAtom } from "jotai";
import { useForm } from "react-hook-form";
import { ValidationError } from "yup";

import {
  BUTTON_LABELS,
  DEFAULT_PAGE_SIZES,
  FORM_HELPER_ERROR_CLASS,
  HUB_MESSAGE,
  SUB_HEADERS,
  TITLES
} from "@/constants";
import { AddHubRequest, APIException, DeleteHubsRequest, HubItem, UpdateHubRequest } from "@/interfaces";
import { hubSchema } from "@/validations";
import { snackBarAtom } from "@/stores";
import { useGetHubsData, useCreateNewHub, useDeleteHubs, useUpdateHub, usePagination } from "@/hooks";
import { Dialog } from "@/components/dialogs";
import { AppFormInput } from "@/components/fields";

const DATA_GRID_COLUMNS: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1
  }
];

export default function HubsConfigurationItem() {
  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination();

  const setSnackBar = useSetAtom(snackBarAtom);

  const [rowIsSelected, setRowIsSelected] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(undefined);
  const [selectedHubIds, setSelectedHubIds] = useState<string[]>([]);
  const [modalDialogAction, setModalDialogAction] = useState<"Add" | "Edit">();

  const { data: hubList, isFetching: hubsFetching } = useGetHubsData(currentPage + 1, pageSize);

  const { mutate: addHubMutation, isLoading: addInProgress } = useCreateNewHub();

  const { mutate: updateHubMutation, isLoading: updateInProgress } = useUpdateHub();

  const { mutate: deleteHubsMutation, isLoading: deleteInProgress } = useDeleteHubs();

  const editButtonDisabled = !rowIsSelected || selectedHubIds.length !== 1;
  const deleteButtonDisabled = !rowIsSelected || deleteInProgress;
  const modalDialogIsOpen = modalDialogAction !== undefined;

  const defaultValues: HubItem = {
    id: "",
    name: ""
  };

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    reset,
    setError,
    watch
  } = useForm<HubItem>({ defaultValues });

  const selectedHub = useMemo(
    () => hubList.items.find((hub) => hub.id === selectedRowId),
    [hubList.items, selectedRowId]
  );

  const dialogAddButtonDisabled = watch("name") === "";
  const dialogSaveButtonDisabled = [selectedHub?.name, ""].includes(watch("name"));

  const handleAPIError = (error: APIException) => {
    if (error && error.BadRequest !== undefined) {
      error.BadRequest.forEach((itm) => {
        setError(itm.propertyName.toLowerCase() as keyof HubItem, {
          message: itm.errorMessage
        });
      });
    }
  };

  const handleDialogOpen = (action?: "Add" | "Edit") => {
    reset(selectedHub);
    setModalDialogAction(action);
  };

  const handleDialogClose = () => {
    setModalDialogAction(undefined);
    reset(defaultValues);
  };

  const handleSave = () => {
    const formValues = getValues();

    if (selectedRowId !== undefined && modalDialogAction === "Edit") {
      const command: UpdateHubRequest = { id: selectedRowId, name: formValues.name };
      validateHub(command).then(() => {
        updateHubMutation(command, {
          onSuccess: () => {
            handleDialogClose();
            setSnackBar({ message: HUB_MESSAGE.EDIT_SUCCESSFUL, open: true });
          },
          onError: (error) => {
            const apiException = error as APIException;
            if (apiException.BadRequest !== undefined) {
              handleAPIError(error as APIException);
            } else {
              setError("name", {
                message: "Error while attempting action. Please contact an administrator."
              });
            }
          }
        });
      });
    } else {
      const command: AddHubRequest = { name: formValues.name };
      validateHub(command).then(() =>
        addHubMutation(command, {
          onSuccess: () => {
            handleDialogClose();
            setSnackBar({
              message: HUB_MESSAGE.SAVE_SUCCESSFUL,
              open: true
            });
          },
          onError: (error) => {
            const apiException = error as APIException;
            if (apiException.BadRequest !== undefined) {
              handleAPIError(error as APIException);
            } else {
              setError("name", {
                message: "Error while attempting action. Please contact an administrator."
              });
            }
          }
        })
      );
    }
  };

  const validateHub = (command: UpdateHubRequest | AddHubRequest) =>
    hubSchema.validate(command, { abortEarly: false }).catch(({ inner }: ValidationError) => {
      inner.forEach(({ path, message }) => {
        setError(path as keyof HubItem, { message });
      });
    });

  const handleDelete = () => {
    const deleteHubCommand: DeleteHubsRequest = selectedHubIds;

    deleteHubsMutation(deleteHubCommand, {
      onSuccess: () => {
        handleDialogClose();
        setSnackBar({
          message: HUB_MESSAGE.DELETE_SUCCESSFUL,
          open: true
        });
      }
    });
  };

  const onRowDoubleClick = (item: GridRowModel) => {
    setSelectedRowId(item.id.toString());
    handleDialogOpen("Edit");
  };

  function GridActionButtons() {
    return (
      <Box>
        <LoadingButton
          data-testid="hub-button-add"
          variant="text"
          onClick={() => handleDialogOpen("Add")}
          loading={addInProgress || updateInProgress}
        >
          Add
        </LoadingButton>
        <LoadingButton
          data-testid="hub-button-edit"
          variant="text"
          onClick={() => {
            handleDialogOpen("Edit");
          }}
          disabled={editButtonDisabled}
          loading={addInProgress || updateInProgress}
        >
          Edit
        </LoadingButton>
        <Button
          data-testid="hub-button-delete"
          variant="text"
          onClick={() => {
            handleDelete();
          }}
          disabled={deleteButtonDisabled}
        >
          Delete
        </Button>
      </Box>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <Typography
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        No rows
      </Typography>
    );
  }

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader title={TITLES.hubs} subheader={SUB_HEADERS.hubs} action={<GridActionButtons />} />
      <CardContent>
        <DataGridPro
          autoHeight={true}
          paginationMode="server"
          data-testid="admin-table-Id"
          columns={DATA_GRID_COLUMNS}
          rows={hubList.items}
          rowCount={hubList.totalRecords}
          getRowId={(row: HubItem) => row.id}
          checkboxSelection
          pagination={true}
          paginationModel={{
            pageSize: pageSize,
            page: currentPage
          }}
          loading={hubsFetching}
          onPaginationModelChange={paginationModelChangeHandler}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          slots={{
            noResultsOverlay: CustomNoRowsOverlay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          onRowSelectionModelChange={(ids: GridRowSelectionModel) => {
            if (ids.length > 0) {
              setSelectedHubIds(ids.map((id) => id.toString()));
              setSelectedRowId(ids[0].toString());
              setRowIsSelected(true);
            } else {
              setSelectedRowId(undefined);
              setRowIsSelected(false);
            }
          }}
          onRowDoubleClick={(item: GridRowModel) => {
            onRowDoubleClick(item);
          }}
        />
      </CardContent>
      <Dialog
        fullWidth={true}
        data-testid={"admin-hub-modal"}
        open={modalDialogIsOpen}
        onClose={handleDialogClose}
        title={modalDialogAction === "Edit" ? "Update Hub" : "New Hub"}
        actions={[
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: handleDialogClose,
            disabled: addInProgress || updateInProgress
          },
          {
            label: modalDialogAction === "Edit" ? BUTTON_LABELS.SAVE : BUTTON_LABELS.ADD,
            disabled: modalDialogAction === "Edit" ? dialogSaveButtonDisabled : dialogAddButtonDisabled,
            onClick: handleSave,
            isLoading: addInProgress || updateInProgress
          }
        ]}
      >
        <AppFormInput
          data-testid="hub-name-input"
          name={"name"}
          label={"Name"}
          control={control}
          required={true}
          size="small"
          inputProps={{ maxLength: 500 }}
          error={errors.name}
          resetError={() => {
            clearErrors("name");
          }}
          formHelperErrorClass={FORM_HELPER_ERROR_CLASS.AR_DETAILS}
        />
      </Dialog>
    </Card>
  );
}

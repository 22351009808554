import { Box } from "@mui/material";

import { AREventHistory } from "@/interfaces";
import { RichTextEditor } from "@/components/rich-text-editor";

interface HistoryRecordDetailProps extends AREventHistory {}

export function HistoryRecordDetail({ content }: HistoryRecordDetailProps) {
  return (
    <Box p={"1rem 4rem"}>
      <RichTextEditor
        defaultValue={content}
        hideMenuBar={true}
        readOnly={true}
        hasMention={false}
        data-testid={`history-content`}
      />
    </Box>
  );
}

import { useRef, useEffect } from "react";

import { validateMapUploadFile } from "@/validations";

interface UploadComponentProps {
  handleUpload: (file: File) => void;
  allowMultipleFile: boolean;
  acceptedFileExtensions: string[];
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  uploadFileList?: FileList | undefined;
  validateMapFile?: boolean;
}

export function UploadComponent({
  handleUpload,
  allowMultipleFile,
  acceptedFileExtensions,
  setErrorMessage,
  uploadFileList,
  validateMapFile
}: UploadComponentProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFiles = (fileList: FileList | null) =>
    new Promise(async (resolve: (files: File[]) => void, reject: (errorMessage: string) => void) => {
      if (fileList === null) {
        return reject("File list was null");
      }

      if (fileList.length <= 0) {
        return reject("File list was empty");
      }

      if (validateMapFile) {
        await validateMapUploadFile(fileList, reject);
      }

      return resolve(Array.from(fileList));
    });

  const uploadFiles = (files: File[]) => {
    files.forEach((file) => {
      handleUpload(file);
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    setErrorMessage(undefined);
  };

  const validateAndUploadFiles = (fileList: FileList | null) => {
    setErrorMessage(undefined);
    validateFiles(fileList)
      .then(uploadFiles)
      .catch((errorMessage) => setErrorMessage(errorMessage));
  };

  useEffect(() => {
    if (uploadFileList) {
      validateAndUploadFiles(uploadFileList);
    }
  }, [uploadFileList]);

  return (
    <input
      ref={fileInputRef}
      id="fileInput"
      type="file"
      multiple={allowMultipleFile}
      accept={acceptedFileExtensions.join(", ")}
      style={{ display: "none" }}
      onChange={(event) => {
        validateAndUploadFiles(event.target.files);
      }}
    />
  );
}

import { array, boolean, date, number, object, string } from "yup";
import dayjs from "dayjs";

import { AR_BD_ERRORS, AR_QA_ERRORS, AR_WC_ERRORS, MANDATORY_FIELD } from "@/constants";
import { greaterThanOrEqualTo } from "@/utils";
const arBasicDetailsSaveSchema = object({
  title: string().trim().required(MANDATORY_FIELD.DEFAULT),
  hubId: string().trim().optional(),
  siteId: string().trim().optional(),
  businessUnitId: string().trim().optional(),
  projectId: string().trim().optional(),
  costCode: string().trim().optional(),
  optional: date().optional(),
  description: string().trim().optional()
});

export const arBasicDetailsSubmitSchema = object({
  title: string().trim().required(MANDATORY_FIELD.DEFAULT),
  hubId: string().trim().required(MANDATORY_FIELD.DEFAULT),
  siteId: string().trim().required(MANDATORY_FIELD.DEFAULT),
  businessUnitId: string().trim().required(MANDATORY_FIELD.DEFAULT),
  projectId: string().trim().required(MANDATORY_FIELD.DEFAULT),
  costCode: string().test("costCode", AR_BD_ERRORS.COST_CODE, (costCode) => !(costCode && costCode.trim() === "")),
  description: string()
    .required(MANDATORY_FIELD.DEFAULT)
    .test("Empty HTML", MANDATORY_FIELD.DEFAULT, (value) => {
      const element = document.createElement("div");
      element.innerHTML = value;
      return (element.textContent ?? "").trim() !== "";
    })
});

const requiredByDateSchema = object({
  requiredByDate: date()
    .optional()
    .nullable()
    .test(
      "minimum date check",
      AR_BD_ERRORS.REQUIRED_BY_DATE,
      (value) => value === undefined || value === null || greaterThanOrEqualTo(dayjs(value), dayjs().add(4, "months"))
    )
});

const requiredByDateCoordinatorSchema = object({
  requiredByDate: date().optional().nullable()
});

const questionAnswerSchema = object({
  allQuestionsAnswered: boolean().oneOf([true], MANDATORY_FIELD.QA_SECTION),
  approvalRequestAnswers: array().of(
    object({
      questionId: string().trim().required(),
      id: string().trim().nullable(),
      isConditional: boolean().required(),
      componentName: string().trim().required(),
      answer: string()
        .trim()
        .test("Dynamic question validation", (value, { parent: { isConditional, componentName }, createError }) => {
          if (isConditional) return true;
          if (componentName === "numericText") {
            if (!value || value === "")
              return createError({
                message: AR_QA_ERRORS.INVALID_CLEARING_VALUE
              });

            const numberValue = parseFloat(value);
            if (!isNaN(numberValue) && numberValue <= 0) {
              return createError({
                message: AR_QA_ERRORS.INVALID_CLEARING_VALUE
              });
            }
          } else if (value === "") {
            return createError({
              path: "approvalRequestAnswers",
              message: MANDATORY_FIELD.QA_SECTION
            });
          }
          return true;
        })
    })
  )
});

const workCategorySchema = object({
  approvalRequestWorkCategories: array().of(
    object({
      workCategoryId: string().trim().required(),
      workSubCategoryId: string().trim().required(),
      answer: number().moreThan(0, AR_WC_ERRORS.INVALID_AREA_VALUE).required()
    })
  )
});

const arMapSchema = object({
  mapUploaded: boolean().required().isTrue(MANDATORY_FIELD.DEFAULT)
});

const mapSensitivitySchema = object({
  isMapSensitivityAnalysed: boolean().required().isTrue(MANDATORY_FIELD.DEFAULT)
});

export const noValidationSchema = object({});

export const saveARSchema = () => arBasicDetailsSaveSchema;

export const submitARSchema = (isCoordinator: boolean) => {
  let baseSchema = arBasicDetailsSubmitSchema
    .concat(questionAnswerSchema)
    .concat(workCategorySchema)
    .concat(arMapSchema)
    .concat(mapSensitivitySchema);

  baseSchema = isCoordinator
    ? baseSchema.concat(requiredByDateCoordinatorSchema)
    : baseSchema.concat(requiredByDateSchema);

  return baseSchema;
};

import { useState, DragEvent } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { colours } from "@/theme/colour";

import { UploadComponent } from "@/components/upload";

const defaultUploadBackgroundColor = "rgba(0, 0, 0, 0.05)";
const onHoverBackgroundColor = "rgba(0, 91, 209, 0.08)";
interface AttachmentUploadBoxProps {
  handleUpload: (file: File) => void;
  acceptedFileExtensions: string[];
  allowMultipleFile: boolean;
  fillHeight?: boolean;
  customFooter?: JSX.Element;
  validateMapFile?: boolean;
}

export function AttachmentUploadBox({
  handleUpload,
  acceptedFileExtensions,
  allowMultipleFile,
  fillHeight,
  customFooter,
  validateMapFile
}: AttachmentUploadBoxProps) {
  const [uploadBoxIsHovered, setUploadBoxIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [uploadFileList, setUploadFileList] = useState<FileList | undefined>();

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUploadBoxIsHovered(true);
    setErrorMessage(undefined);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setUploadBoxIsHovered(false);
  };

  const handleDropUpload = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setUploadBoxIsHovered(false);
    setUploadFileList(event.dataTransfer.files);
  };

  return (
    <Grid
      container
      component="div"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDropUpload}
      border={`1px dashed ${uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor}`}
      height={fillHeight ? "100%" : "8rem"}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      sx={{
        border: errorMessage
          ? "2px solid red" // Solid red border if there's anp error
          : `1px dashed ${uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor}`,
        backgroundColor: uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor,
        marginTop: "auto",
        borderRadius: 1
      }}
      data-testid="attachment-upload-box"
    >
      <Avatar sx={{ bgcolor: "#1976D21F" }}>
        <UploadFileIcon color={errorMessage ? "error" : "primary"} />
      </Avatar>

      <Box pt={"0.75rem"}>
        <UploadComponent
          handleUpload={handleUpload}
          allowMultipleFile={allowMultipleFile}
          acceptedFileExtensions={acceptedFileExtensions}
          setErrorMessage={setErrorMessage}
          uploadFileList={uploadFileList}
          validateMapFile={validateMapFile}
        />
        <label htmlFor="fileInput">
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer"
              },
              textDecoration: "underline",
              color: colours.hyperlink
            }}
          >
            Click to upload
          </Typography>
        </label>
        <Typography variant="body1">&nbsp;or drag and drop.</Typography>
      </Box>
      {errorMessage ? (
        <Typography variant="body1" color="error" data-testid="attachment-upload-box-error">
          {errorMessage}
        </Typography>
      ) : (
        customFooter
      )}
    </Grid>
  );
}

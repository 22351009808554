import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Geometry from "@arcgis/core/geometry/Geometry";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MapView from "@arcgis/core/views/MapView";
import { intersect, union, buffer } from "@arcgis/core/geometry/geometryEngine.js";
import { Symbol as EsriSymbol } from "@arcgis/core/symbols";
import { find } from "lodash";

import { GeometryDto, LayerBuffer, SensitivityResult, SensitivityRule } from "@/interfaces";

export function drawShapes(graphicsLayer: GraphicsLayer, geometries: Geometry | Geometry[], symbol?: EsriSymbol) {
  if (Array.isArray(geometries)) {
    geometries.forEach((geometry) => drawShapes(graphicsLayer, geometry, symbol));
    return;
  }

  graphicsLayer.add(
    new Graphic({
      geometry: geometries,
      symbol
    })
  );
}

export function getGeometryOverlap(
  baseGeometries: Geometry[],
  intersectingGeometries: Graphic[],
  layerBuffers: LayerBuffer[]
) {
  const results: Geometry[] = [];
  const convertedBaseGeometry = union(baseGeometries);

  const addIntersectionResult = (intersectionResults: Geometry | Geometry[]) => {
    if (Array.isArray(intersectionResults)) {
      intersectionResults.forEach((intersection) => results.push(intersection));
      return;
    }
    results.push(intersectionResults);
  };

  intersectingGeometries.forEach((intersectingGeometry) => {
    const layerBuffer = find(layerBuffers, { name: intersectingGeometry.attributes?.name });

    if (layerBuffer && layerBuffer.buffer && layerBuffer.buffer > 0) {
      const bufferedBaseGeometry = buffer(convertedBaseGeometry, layerBuffer.buffer);
      addIntersectionResult(intersect(intersectingGeometry.geometry, bufferedBaseGeometry as Geometry));
    } else {
      addIntersectionResult(intersect(intersectingGeometry.geometry, convertedBaseGeometry));
    }
  });

  return results;
}

export function concatGraphics(geometries: GeometryDto[] | Geometry[]) {
  return geometries.map((geometry) => Graphic.fromJSON(geometry));
}

export function getLayerBuffers(geometries: GeometryDto[]): LayerBuffer[] {
  const layerHasBuffer = (layerBuffer: LayerBuffer) => {
    return layerBuffer.name && layerBuffer.buffer && layerBuffer.buffer > 0;
  };
  return geometries
    .map((shapeGeometry) => {
      return {
        name: shapeGeometry.attributes?.name,
        buffer: shapeGeometry.buffer
      };
    })
    .filter(layerHasBuffer);
}

export function refreshMap(mapView: MapView) {
  mapView.extent = mapView.extent;
}

// execute a sensitivity query based on a rule and AR geometry
export function executeSensitivityQuery(rule: SensitivityRule, geometry: Geometry): Promise<SensitivityResult> {
  // hand back a Promise so that multiple queries can be executed in parallel
  return new Promise<SensitivityResult>((resolve) => {
    // if the rule is not active, we can skip it
    if (rule.active !== "true") {
      const emptyResult: SensitivityResult = {
        name: rule.name,
        items: [],
        subjectGeometry: geometry,
        getLabel: rule.getLabel
      };
      resolve(emptyResult);
    }

    let subjectGeometry: Geometry;

    // get a feature layer reference
    const sensitivity_feature_layer = new FeatureLayer({
      url: rule.layer_url
    });

    // create the query based on a combination of geometry and sql expression
    const sensitivity_query = sensitivity_feature_layer.createQuery();
    sensitivity_query.spatialRelationship = "intersects";
    sensitivity_query.geometry = geometry;
    sensitivity_query.where = rule.query;
    if (rule.buffer === 0) {
      // if the buffer is zero, we can use the AR geometry as the subject geometry
      subjectGeometry = geometry;
    } else {
      sensitivity_query.distance = rule.buffer;
      sensitivity_query.units = "meters";
      subjectGeometry = buffer(geometry, rule.buffer) as Geometry;
    }

    // execute the query and compose the results
    sensitivity_feature_layer.queryFeatures(sensitivity_query).then((sensitivity_response) => {
      const features = sensitivity_response.features;

      const result: SensitivityResult = {
        name: rule.name,
        items: [],
        subjectGeometry: subjectGeometry,
        getLabel: rule.getLabel
      };

      // add each feature to the "rule" or "category" result
      features.forEach((f) => {
        const intersection = intersect(f.geometry, subjectGeometry) as Geometry;
        const graphic = new Graphic({ geometry: f.geometry, attributes: f.attributes });
        result.items.push({ graphic: graphic, intersection: intersection, sensitiveLayerGeometry: f.geometry });
      });

      resolve(result);
    });
  });
}

import { Alert, AlertTitle, Button } from "@mui/material";

import { AlertBannerConfig } from "@/interfaces";
import { useARContext } from "@/context";
import { generatePath, useNavigate } from "react-router-dom";
import {
  APPROVAL_REQUEST_PATH,
  AR_SUBMIT_MAP_ERROR_BANNER,
  AR_SUBMIT_MAP_SENSITIVITY_ANALYSED_ERROR_BANNER
} from "@/constants";

interface AlertBannerProps extends AlertBannerConfig {}

export function ApprovalRequestAlertBanner({ id, title, message, severity = "error" }: AlertBannerProps) {
  const { removeAlertBanner, approvalRequestId } = useARContext();
  const navigate = useNavigate();

  return (
    <Alert
      severity={severity}
      onClose={() => {
        removeAlertBanner([id]);
      }}
      data-testid={`${id}-${severity}-alert-banner`}
      sx={{ position: "sticky", top: "70px", zIndex: "100", whiteSpace: "pre-line" }}
    >
      <AlertTitle>{title}</AlertTitle>
      {message}
      {[AR_SUBMIT_MAP_ERROR_BANNER.id, AR_SUBMIT_MAP_SENSITIVITY_ANALYSED_ERROR_BANNER.id].includes(id) && (
        <Button
          color="inherit"
          size="small"
          data-testid="map-error-view-button"
          onClick={() => {
            navigate(generatePath(APPROVAL_REQUEST_PATH.MAP_TAB, { approvalRequestId: approvalRequestId }));
          }}
          sx={{ position: "absolute", right: "64px", top: "10px" }}
        >
          VIEW
        </Button>
      )}
    </Alert>
  );
}

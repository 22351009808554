import { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";

import { AR_STATUSES } from "@/constants";
import { ApprovalRequestStatus } from "@/interfaces";
import { formatDateTime } from "@/utils";
import { useARContext } from "@/context";
import { useApprovalRequestEventHistory } from "@/hooks";
import { HistoryRecordDetail } from "./HistoryRecordDetail";

const RoleFilterOptions: Record<string, string> = {
  all: "All",
  requestor: "Requestor",
  coordinator: "Coordinator",
  discipline: "Discipline",
  endorser: "Endorser"
};

export const HISTORY_COLUMNS: GridColDef[] = [
  {
    field: "event",
    headerName: "Event description",
    flex: 2
  },
  {
    field: "role",
    headerName: "Role",
    flex: 1
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    renderCell: ({ row }) => AR_STATUSES[row.status as ApprovalRequestStatus]
  },
  {
    field: "version",
    headerName: "Version",
    flex: 1
  },
  {
    field: "person",
    headerName: "Person",
    flex: 2
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    renderCell: ({ row }) => formatDateTime(row.date)
  }
];

export function HistoryTab() {
  const { approvalRequestId } = useARContext();

  const { data: historyRecords, isFetching: historyFetching } = useApprovalRequestEventHistory(approvalRequestId);

  const [roleFilter, setRoleFilter] = useState<string>("all");

  return (
    <Box height={"auto"}>
      <FormControl size="small" sx={{ mb: "1rem", width: "10rem" }}>
        <InputLabel>Role (Mocked)</InputLabel>
        <Select
          label="Role (Mocked)"
          data-testid="history-rule-select"
          value={roleFilter}
          onChange={(event) => setRoleFilter(event.target.value)}
          size="small"
        >
          {Object.entries(RoleFilterOptions).map(([role, label]) => (
            <MenuItem selected={role === roleFilter} value={role}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <DataGridPro
        data-testid="history-records-table"
        loading={historyFetching}
        rows={historyRecords}
        columns={HISTORY_COLUMNS}
        getDetailPanelContent={({ row }) => (row.expandable ? <HistoryRecordDetail {...row} /> : null)}
        getDetailPanelHeight={() => "auto"}
        disableRowSelectionOnClick
      ></DataGridPro>
    </Box>
  );
}

import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const { REACT_APP_INSIGHTS_CONNECTION_STRING } = import.meta.env;

// https://github.com/microsoft/applicationinsights-react-js/issues/13 using react-router6 createBrowserHistory doesn't work anymore
// so using enableAutoRouteTracking is the only way to track page views however you lose some functionality which might impact future wants/needs
const reactPlugin = new ReactPlugin();
const appInsightsKey = REACT_APP_INSIGHTS_CONNECTION_STRING;

console.log("App insights", appInsightsKey);

let appInsights: ApplicationInsights | undefined;
if (appInsightsKey) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: appInsightsKey,
      extensions: [reactPlugin],
      enableAutoRouteTracking: true,
      disableAjaxTracking: false,
      autoTrackPageVisitTime: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true
    }
  });
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };

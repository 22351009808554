import { useMutation, useQueryClient } from "@tanstack/react-query";

import { apiClient } from "@/api/client";
import { SensitivityRule } from "@/interfaces";
import { APPROVAL_REQUEST_MAP_QUERY_KEY } from "@/constants";

const basePath = "/ApprovalRequestMap";

export function usePatchIsMapSensitivityAnalysed(approvalRequestId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => apiClient.patch(`${basePath}/${approvalRequestId}/sensitivity-analysed`),
    onSuccess: () => {
      queryClient.invalidateQueries([APPROVAL_REQUEST_MAP_QUERY_KEY, approvalRequestId]);
    }
  });
}

export function useGetSensitivityRules() {
  const sensitivityRules: SensitivityRule[] = [
    {
      active: "true",
      name: "Heritage",
      layer_url:
        "https://gismaptest.corp.riotinto.org/arcgis/rest/services/Heritage/Integrated_Heritage_Management/FeatureServer/0",
      query: "Internal_Site_Status not in ('Cleared-CC', 'Cleared-D') and Approved_Impact = 'Not yet evaluated'",
      buffer: 25,
      display_fields: ["Internal_Site_Status", "Field_Site_No"],
      getLabel: (ruleName, attributes) => `${ruleName}, ${attributes?.Field_Site_No}`
    },
    {
      active: "true",
      name: "Exclusion Sites",
      layer_url: "https://gismaptest.corp.riotinto.org/arcgis/rest/services/ADT/Approvals_ADT/MapServer/6",
      query:
        "(Site_category = 'Biological Exclusion' or Site_Category = 'Biological Interim Exclusion') and Site_type <> 'Heritage'",
      buffer: 0,
      display_fields: ["Site_Category", "Field_Site_No", "Reason"],
      getLabel: (ruleName, attributes) => `${ruleName}, Exclusion, ${attributes?.Field_Site_No}`
    }
  ];
  return sensitivityRules;
}

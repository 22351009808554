import { useState } from "react";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Grid, IconButton, Stack, Typography } from "@mui/material";
import MapView from "@arcgis/core/views/MapView";

import { SensitivityIssueListRecord } from "@/interfaces";

interface MapSensitivityOverlayProps {
  mapView: MapView;
  hideList: () => void;
  sensitivityIssues: SensitivityIssueListRecord[];
  showList: boolean;
}

export function MapSensitivityOverlay({ showList, hideList, mapView, sensitivityIssues }: MapSensitivityOverlayProps) {
  const [expanded, setExpanded] = useState<boolean>(true);

  if (sensitivityIssues.length === 0) {
    sensitivityIssues.push({ label: "No sensitive areas detected", extent: null });
  }

  return (
    <Stack sx={{ background: "white", display: showList ? "flex" : "none" }} p={1} width="20rem" maxHeight="15rem">
      <Stack
        direction="row"
        alignItems="center"
        sx={{ borderBottom: expanded ? "1px solid #e0e0e0" : undefined }}
        minHeight="2rem"
      >
        <Typography fontSize="0.8rem" marginRight="auto">
          Sensitivity Areas Issues
        </Typography>
        <IconButton onClick={() => setExpanded((old) => !old)}>
          {expanded ? <ExpandLess sx={{ fontSize: "0.8rem" }} /> : <ExpandMore sx={{ fontSize: "0.8rem" }} />}
        </IconButton>
        <IconButton
          onClick={() => {
            hideList();
            setExpanded(true);
          }}
        >
          <Close sx={{ fontSize: "0.8rem" }} />
        </IconButton>
      </Stack>
      <Collapse in={expanded} sx={{ m: 0, p: 0, overflow: "auto" }} unmountOnExit>
        <Grid container sx={{ overflow: "auto" }}>
          {sensitivityIssues.length > 0 ? (
            sensitivityIssues.map(({ label, extent }, index) => {
              return (
                <Grid
                  key={`${label}-${index}`}
                  item
                  xs={12}
                  p={1}
                  sx={{ px: "1rem", borderBottom: "1px solid #e0e0e0" }}
                  onClick={() => {
                    mapView.goTo(extent);
                  }}
                >
                  <Typography className="records" fontSize={"0.8rem"}>
                    {label}
                  </Typography>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} p={1} sx={{ px: "1rem" }}>
              <Typography className="records" fontSize={"0.8rem"}>
                No sensitive areas detected
              </Typography>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Stack>
  );
}

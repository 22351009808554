import { useMemo } from "react";
import { generatePath } from "react-router-dom";

import { APPROVAL_REQUEST_PATH } from "@/constants";
import { useARContext } from "@/context";
import { PermitView } from "./PermitView";

interface PermitTabProps {
  activePermit?: boolean;
}

export function PermitTab({ activePermit }: PermitTabProps) {
  const {
    approvalRequestId,
    approvalRequest: { approvalRequestStatus, currentVersion }
  } = useARContext();

  const version = useMemo(() => {
    return activePermit ? parseInt(currentVersion).toFixed(1) : undefined;
  }, [currentVersion, activePermit]);

  function onClickPrintPermit() {
    const pathToNavigate = generatePath(APPROVAL_REQUEST_PATH.PERMIT_PREVIEW, { approvalRequestId: approvalRequestId });
    const versionParam = `?version=${version}`;
    window.open(`${window.location.origin}${pathToNavigate}` + (version ? versionParam : ""), "_blank");
  }

  return (
    <PermitView
      approvalRequestId={approvalRequestId ?? ""}
      approvalRequestStatus={approvalRequestStatus}
      isPrintPreview={false}
      onPrintClick={onClickPrintPermit}
      version={version}
    />
  );
}

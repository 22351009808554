import { MouseEventHandler } from "react";
import { QAAnswerItem, PaginatedList, ApprovalRequestDisciplineStatuses } from "./api";

export type ApprovalRequestList = PaginatedList<ApprovalRequestBase>;

export interface ARBasicDetails {
  title: string;
  hubId: string;
  siteId: string;
  businessUnitId: string;
  projectId: string;
  costCode: string;
  description: string;
  requiredByDate: Date | null;
  extendBy?: string;
}

interface ARQuestionAnswer {
  approvalRequestAnswers: QAAnswerItem[];
}

interface ARWorkCategory {
  approvalRequestWorkCategories: ApprovalRequestWorkCategoryItem[];
}

export interface PastARVersion {
  approvalRequestVersion: string;
  status: ApprovalRequestStatus;
}

interface ARMetadata {
  approvalRequestTypeName: string;
  approvalRequestStatusName: string;
  ownerName: string;
  hub: string;
  site: string;
  requestorName: string;
  coordinatorName: string;
  endorserName: string;
  submitted: Date;
  permitExpiryDate: Date | null;
  created: Date;
  lastModified: Date;
  lastModifiedByName: string;
  pastApprovalRequests: PastARVersion[];
}

interface ARIdentifiers {
  id: string;
  approvalRequestTypeId: string;
  referenceNo: string;
  approvalRequestStatus: ApprovalRequestStatus;
  ownerId: string;
  requestorId: string;
  coordinatorId: string;
  endorserId: string;
  returnRequestCount: number;
  currentVersion: string;
  cycle: Cycle | undefined;
}

export interface ARDisciplines {
  approvalRequestDisciplineStatuses?: ApprovalRequestDisciplineStatuses;
}

export interface ApprovalRequestBase extends ARIdentifiers, ARMetadata, ARBasicDetails, ARDisciplines {}

export interface ARFormValues extends Partial<ARBasicDetails>, ARQuestionAnswer, ARWorkCategory {
  allQuestionsAnswered: boolean;
  mapUploaded: boolean;
  isMapSensitivityAnalysed: boolean;
}

export interface UpdateApprovalRequest
  extends Omit<
      ARFormValues,
      "allQuestionsAnswered" | "approvalRequestAnswers" | "mapUploaded" | "isMapSensitivityAnalysed"
    >,
    ARIdentifiers {
  approvalRequestAnswers: QAAnswerItem[];
}

export interface ApprovalRequestWorkCategoryItem {
  id: string | undefined;
  workCategoryId: string | undefined;
  workSubCategoryId: string | undefined;
  answer: number;
  approvalRequestId: string;
  displayOrder: number;
  isMandatory: boolean;
}

export interface SnackbarConfig {
  message: string;
  open: boolean;
  handleCloseOnClicked?: () => void;
}

export interface ApprovalRequestType {
  id: string;
  name: ARType;
  description: string;
  referencePrefix: string;
}

export interface WorkSubcategoryMapping {
  ApprovalRequestTypeId: string;
  QuestionList: WCQuestionItem[];
}

export interface WCQuestionItem {
  QuestionId: string;
  Answer: string;
}

export enum ApprovalRequestStatus {
  New = 0,
  Draft = 1,
  Submitted = 2,
  Distributed = 3,
  DraftPermit = 4,
  PermitIssued = 5,
  Authorised = 6,
  Completed = 7,

  // TODO: Temporary mapping for data migration
  LegacyPermitIssued = 104,
  LegacyCompleted = 105,
  LegacyDeclinedOrReplaced = 106,
  LegacyPermitExpired = 107,
  LegacyPermitDrafted = 108,
  LegacyPermitReviewed = 109,
  LegacyAmendmentRequested = 110,
  LegacyReissueRequested = 111,
  LegacyExtensionRequested = 112,
  LegacyReissueDrafted = 113,
  LegacyReissueReviewed = 114,
  LegacyExtensionDrafted = 115,
  LegacyExtensionReviewed = 116,
  LegacyClosureRequested = 117
}

export enum ARType {
  Planning = "Planning",
  Survey = "Survey Only",
  Blasting = "Blasting",
  Ground = "Ground Disturbance",
  Maintenance = "Maintenance",
  Rehabilitation = "Rehabilitation"
}

export enum ARFormSection {
  BasicForm,
  Question,
  WorkCategory,
  Attachment,
  ProgrammeOfWork
}

export enum ApprovalRequestDisciplineStatus {
  NotUsed = 0,
  NotDistributed = 1,
  NotStarted = 2,
  WIP = 3,
  Authorised = 4
}

export enum AR_ACTION_BUTTONS {
  Save = "Save",
  Delete = "Delete",
  Submit = "Submit",
  Modify = "Modify",
  Return = "Return",
  Distribute = "Distribute",
  SMEAuthorise = "SMEAuthorise",
  EndorserAuthorise = "EndorserAuthorise",
  EndorserReturn = "EndorserReturn",
  Amend = "Amend",
  CreateCopy = "CreateACopy"
}

export interface ARActionButtonProps {
  name: string;
  isEnabled: boolean;
  onClick: MouseEventHandler;
  component: ARActionButtonComponentName;
  iconName?: string;
  isVisible?: boolean;
}

export enum ARActionButtonComponentName {
  Icon = "Icon",
  ButtonGroup = "ButtonGroup"
}

export interface ARSearchResult extends ARDisciplines {
  id: string;
  referenceNo: string;
  title: string;
  hubId: string;
  hubName: string;
  siteId: string;
  siteName: string;
  businessUnitId: string;
  businessUnitName: string;
  projectId: string;
  projectName: string;
  requiredByDate: Date | null;
}

export enum ARSearchClearingOption {
  Clearing,
  NoClearing
}

export interface PermitResponse {
  referenceNo: string;
  title: string;
  site: string;
  project: string;
  clearing: string;
  expiryDate: Date;
  authorisedBy: string;
  coordinator: string;
  permitOwner: string;
  queriesStatement: string;
  authorisedActivities: string;
  permitOwnerCompliance: string[];
  standardConditions: string[];
  disciplineActivities: DisciplineActivity[];
  approvalRequestVersion: string;
}

export interface DisciplineActivity {
  name: string;
  authorisedByName: string;
  authorisationComments: PermitAuthorisationComment[];
  authorisedDate: Date;
}

export interface PermitAuthorisationComment {
  permitContent: string;
  commentValues: Record<string, CommentValue>;
  defaultValues: Record<string, string>;
}

export interface CommentValue {
  componentType: string;
  value: string | Record<string, CommentValue>;
}

export enum TimelineStatus {
  Past = 0,
  Current = 1,
  Future = 2,
  Compulsory = 3
}
export enum Cycle {
  Amendment = 0, // Changing the scope
  Extension = 1 // Extending permit expiry date
}

export interface AmendPermitFormFields {
  cycle: Cycle;
  extendBy?: number;
}

export enum DistributionType {
  Default,
  Redistribute,
  Review
}

export interface RedistributeARFormFields {
  disciplinesToUnauthorise: string[];
  reason: string;
}

export interface DistributeARRequest extends Partial<RedistributeARFormFields>, Partial<ARBasicDetails> {
  distributionType: DistributionType;
}

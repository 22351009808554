import { ComponentProps, Fragment, FunctionComponent, useState } from "react";
import MapView from "@arcgis/core/views/MapView";
import AreaMeasurement2DViewModel from "@arcgis/core/widgets/AreaMeasurement2D/AreaMeasurement2DViewModel";
import DistanceMeasurement2DViewModel from "@arcgis/core/widgets/DistanceMeasurement2D/DistanceMeasurement2DViewModel";
import { CircularProgress } from "@mui/material";

import { ApprovalRequestStatus } from "@/interfaces";
import { refreshMap } from "@/utils/mapHelpers";
import {
  MapAnalysis,
  MapUploadIcon,
  MapDistance,
  MapDownload,
  MapRecenter,
  MapArea,
  MapFullScreen,
  MapReset,
  MapZoom
} from "@/assets/icons";
import "./MapToolbox.css";

interface PanelGroup {
  label: string;
  buttons: PanelButtonConfig[];
}

interface PanelButtonConfig {
  icon: FunctionComponent<ComponentProps<"svg">>;
  label: string;
  enabledAction: () => void;
  disabledAction: () => void;
  isDisabled: boolean;
  inProgress: boolean;
}

interface MapToolboxProps {
  mapView: MapView;
  approvalRequestStatus: ApprovalRequestStatus;
  isRequestor: boolean;
  viewExtent: __esri.Extent | null;
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  getSensitivityIssues: () => void;
  uploadLoading: boolean;
  downloadLoading: boolean;
  sensitivityAnalysisLoading: boolean;
  downloadMap: () => void;
}

export function MapToolbox({
  mapView,
  approvalRequestStatus,
  isRequestor,
  setFullScreen,
  getSensitivityIssues,
  viewExtent,
  uploadLoading,
  downloadLoading,
  sensitivityAnalysisLoading,
  downloadMap
}: MapToolboxProps) {
  const [clickedButtons, setClickedButtons] = useState<string[]>([]);
  const mapUploadAllowedStatus = [ApprovalRequestStatus.Draft, ApprovalRequestStatus.New];
  const isMapUploadDisabled = !mapUploadAllowedStatus.includes(approvalRequestStatus) || !isRequestor;
  const activeMeasureStatus = ["ready", "measuring"];

  const noAction = () => {};
  const enableFullScreen = () => setFullScreen(true);
  const disableFullScreen = () => setFullScreen(false);
  const zoomToARShape = () => mapView.goTo(viewExtent);

  const [areaMeasurementTool] = useState<AreaMeasurement2DViewModel>(
    new AreaMeasurement2DViewModel({
      view: mapView,
      unit: "metric"
    })
  );

  const [distanceMeasurementTool] = useState<DistanceMeasurement2DViewModel>(
    new DistanceMeasurement2DViewModel({
      view: mapView,
      unit: "metric"
    })
  );

  const getDisabledActions = (enableLabel: string): string[] => {
    const disabledActions = [];
    if (
      enableLabel === "Area" &&
      clickedButtons.includes("Distance") &&
      activeMeasureStatus.includes(distanceMeasurementTool.state)
    ) {
      disabledActions.push("Distance");
    }

    if (
      enableLabel === "Distance" &&
      clickedButtons.includes("Area") &&
      activeMeasureStatus.includes(areaMeasurementTool.state)
    ) {
      disabledActions.push("Area");
    }

    return disabledActions;
  };

  const enableAreaMeasurement = () => areaMeasurementTool.start();
  const disableAreaMeasurement = () => {
    areaMeasurementTool.clear();
    refreshMap(mapView);
  };

  const enableDistanceMeasurement = () => distanceMeasurementTool.start();
  const disableDistanceMeasurement = () => {
    distanceMeasurementTool.clear();
    refreshMap(mapView);
  };

  const panelButtonGroups: PanelGroup[] = [
    {
      label: "Shape Files",
      buttons: [
        {
          icon: MapUploadIcon,
          label: "Upload",
          enabledAction: noAction,
          disabledAction: noAction,
          isDisabled: isMapUploadDisabled,
          inProgress: uploadLoading
        },
        {
          icon: MapDownload,
          label: "Download",
          enabledAction: downloadMap,
          disabledAction: noAction,
          isDisabled: false,
          inProgress: downloadLoading
        }
      ]
    },
    {
      label: "View",
      buttons: [
        {
          icon: MapZoom,
          label: "Zoom to Extents",
          enabledAction: zoomToARShape,
          disabledAction: noAction,
          isDisabled: false,
          inProgress: false
        },
        {
          icon: MapRecenter,
          label: "Re-center",
          enabledAction: noAction,
          disabledAction: noAction,
          isDisabled: false,
          inProgress: false
        },
        {
          icon: MapFullScreen,
          label: "Full Screen",
          enabledAction: enableFullScreen,
          disabledAction: disableFullScreen,
          isDisabled: false,
          inProgress: false
        }
      ]
    },
    {
      label: "Measure",
      buttons: [
        {
          icon: MapDistance,
          label: "Distance",
          enabledAction: enableDistanceMeasurement,
          disabledAction: disableDistanceMeasurement,
          isDisabled: false,
          inProgress: false
        },
        {
          icon: MapArea,
          label: "Area",
          enabledAction: enableAreaMeasurement,
          disabledAction: disableAreaMeasurement,
          isDisabled: false,
          inProgress: false
        }
      ]
    },
    {
      label: "Validation",
      buttons: [
        {
          icon: MapAnalysis,
          label: "Sensitivity Analysis",
          enabledAction: getSensitivityIssues,
          disabledAction: noAction,
          isDisabled: false,
          inProgress: sensitivityAnalysisLoading
        },
        {
          icon: MapReset,
          label: "Reset",
          enabledAction: noAction,
          disabledAction: noAction,
          isDisabled: false,
          inProgress: false
        }
      ]
    }
  ];

  const nonHighlightButtons = ["Upload", "Zoom to Extents", "Sensitivity Analysis", "Download"];

  const buttonClicked = (
    label: string,
    buttonDisabled: boolean = false,
    enabledAction: () => void,
    disabledAction: () => void
  ) => {
    if (buttonDisabled) {
      return;
    }

    if (nonHighlightButtons.includes(label)) {
      enabledAction();
    } else if (!clickedButtons.includes(label)) {
      const disabledButtons = getDisabledActions(label);
      setClickedButtons([...clickedButtons.filter((enabledLabel) => !disabledButtons.includes(enabledLabel)), label]);
      enabledAction();
    } else {
      setClickedButtons(clickedButtons.filter((enabledLabel) => enabledLabel !== label));
      disabledAction();
    }
  };

  const buttonContent = (
    { label, icon: Icon, enabledAction, disabledAction, isDisabled, inProgress }: PanelButtonConfig,
    index: number
  ) => (
    <div
      key={`${label}-${index}`}
      className={
        "panel-button" +
        (clickedButtons.includes(label) ? " panel-button-selected" : "") +
        (isDisabled || inProgress ? " cursor-not-allowed" : "")
      }
      onClick={() => buttonClicked(label, isDisabled || inProgress, enabledAction, disabledAction)}
      data-testid={`map-tool-${label}`}
    >
      <span className="panel-button-icon">
        {isDisabled ? (
          <Icon fill="grey" />
        ) : inProgress ? (
          <CircularProgress className="circularProgress" size="2rem" />
        ) : label === "Upload" ? (
          <label htmlFor="fileInput">
            <Icon fill="black" cursor="pointer" />
          </label>
        ) : (
          <Icon fill={clickedButtons.includes(label) ? "#005BD1DE" : "black"} />
        )}
      </span>
      <span className={"panel-button-text" + (isDisabled || inProgress ? " color-grey" : " color-black")}>
        {label === "Upload" && !isDisabled && !inProgress ? (
          <label htmlFor="fileInput" className="cursor-pointer">
            {label}
          </label>
        ) : (
          label
        )}
      </span>
    </div>
  );

  return (
    <div id="command-panel">
      {panelButtonGroups.map(({ label, buttons }, index) => {
        return (
          <Fragment key={index}>
            {index === 0 ? null : <div className="panel-divider" />}
            <div className="panel-group">
              <div className="panel-group-buttons">{buttons.map(buttonContent)}</div>
              <div className="panel-group-text">{label}</div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

import { Editor } from "@tiptap/react";
import { Divider, Grid, Icon, IconButton, SvgIcon, Tooltip } from "@mui/material";
import {
  UndoOutlined as UndoOutlinedIcon,
  RedoOutlined as RedoOutlinedIcon,
  FormatBoldOutlined as FormatBoldOutlinedIcon,
  FormatItalicOutlined as FormatItalicOutlinedIcon,
  FormatUnderlinedOutlined as FormatUnderlinedOutlinedIcon,
  FormatListBulletedOutlined as FormatListBulletedOutlinedIcon,
  FormatListNumberedOutlined as FormatListNumberedOutlinedIcon,
  FormatAlignLeftOutlined as FormatAlignLeftOutlinedIcon,
  FormatAlignCenterOutlined as FormatAlignCenterOutlinedIcon,
  FormatAlignRightOutlined as FormatAlignRightOutlinedIcon
} from "@mui/icons-material";

import { Heading1Icon, Heading2Icon, Heading3Icon, InkHighlighterIcon } from "@/assets/icons";

interface MenuBarProps {
  editor: Editor | null;
  error?: boolean;
  showError?: boolean;
  disabled?: boolean;
}

export function MenuBar({ editor, disabled }: MenuBarProps) {
  if (!editor) {
    return null;
  }

  return (
    <>
      <Grid container columnSpacing={1}>
        <Grid item>
          <IconButton
            aria-label="undo"
            onClick={() => editor.commands.undo()}
            disabled={!editor.can().undo() || disabled}
          >
            <Tooltip title="Undo">
              <Icon>
                <SvgIcon component={UndoOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>

          <IconButton
            aria-label="redo"
            onClick={() => editor.commands.redo()}
            disabled={!editor.can().redo() || disabled}
          >
            <Tooltip title="Redo">
              <Icon>
                <SvgIcon component={RedoOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="bold"
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run() || disabled}
          >
            <Tooltip title="Bold">
              <Icon>
                <SvgIcon component={FormatBoldOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="italic"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run() || disabled}
          >
            <Tooltip title="Italic">
              <Icon>
                <SvgIcon component={FormatItalicOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="underline"
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={!editor.can().chain().focus().toggleUnderline().run() || disabled}
          >
            <Tooltip title="Underline">
              <Icon>
                <SvgIcon component={FormatUnderlinedOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="bulleted list"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            disabled={!editor.can().chain().focus().toggleBulletList().run() || disabled}
          >
            <Tooltip title="Bulleted List">
              <Icon>
                <SvgIcon component={FormatListBulletedOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="numbered list"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            disabled={!editor.can().chain().focus().toggleOrderedList().run() || disabled}
          >
            <Tooltip title="Numbered List">
              <Icon>
                <SvgIcon component={FormatListNumberedOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="heading 1"
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run() || disabled}
          >
            <Tooltip title="Heading 1">
              <Icon>
                <SvgIcon component={Heading1Icon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="heading 2"
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run() || disabled}
          >
            <Tooltip title="Heading 2">
              <Icon>
                <SvgIcon component={Heading2Icon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="heading 3"
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            disabled={!editor.can().chain().focus().toggleHeading({ level: 3 }).run() || disabled}
          >
            <Tooltip title="Heading 3">
              <Icon>
                <SvgIcon component={Heading3Icon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="align left"
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            disabled={!editor.can().chain().focus().setTextAlign("left").run() || disabled}
          >
            <Tooltip title="Align Left">
              <Icon>
                <SvgIcon component={FormatAlignLeftOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="align center"
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            disabled={!editor.can().chain().focus().setTextAlign("center").run() || disabled}
          >
            <Tooltip title="Align Center">
              <Icon>
                <SvgIcon component={FormatAlignCenterOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="align right"
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            disabled={!editor.can().chain().focus().setTextAlign("right").run() || disabled}
          >
            <Tooltip title="Align Right">
              <Icon>
                <SvgIcon component={FormatAlignRightOutlinedIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Highlight"
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            disabled={!editor.can().chain().focus().toggleHighlight().run() || disabled}
          >
            <Tooltip title="Highlight">
              <Icon>
                <SvgIcon component={InkHighlighterIcon} inheritViewBox />
              </Icon>
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}

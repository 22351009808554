import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { apiClient } from "@/api/client";
import { EMPTY_PAGINATED_RESPONSE, HUBS_DATA_QUERY_KEY } from "@/constants";
import { AddHubRequest, DeleteHubsRequest, HubList, RestoreHubsRequest, UpdateHubRequest } from "@/interfaces";

const basePath = "/hub";

export function useGetHubsData(pageNumber: number, pageSize: number, onSuccess?: (hubs: HubList) => void) {
  return useQuery({
    queryKey: [HUBS_DATA_QUERY_KEY, pageSize, pageNumber],
    queryFn: () =>
      apiClient
        .get<HubList>(`${basePath}`, {
          params: { pageNumber, pageSize }
        })
        .then((response) => response.data),
    onSuccess,
    initialData: EMPTY_PAGINATED_RESPONSE as HubList
  });
}

export function useCreateNewHub() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: AddHubRequest) => apiClient.post<AddHubRequest>(`${basePath}`, request),
    onSuccess: () => {
      queryClient.invalidateQueries([HUBS_DATA_QUERY_KEY]);
    }
  });
}

export function useUpdateHub(onSuccessCallback?: () => void) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: UpdateHubRequest) => apiClient.put(`${basePath}/${request.id}`, request),
    onSuccess: () => {
      onSuccessCallback?.();
      queryClient.invalidateQueries([HUBS_DATA_QUERY_KEY]);
    }
  });
}

export function useDeleteHubs() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: DeleteHubsRequest) => apiClient.post(`${basePath}/bulk-delete`, request),
    onSuccess: () => {
      queryClient.invalidateQueries([HUBS_DATA_QUERY_KEY]);
    }
  });
}

export function useRestoreHubs() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: RestoreHubsRequest) => apiClient.put(`${basePath}/bulk-restore`, request),
    onSuccess: () => {
      queryClient.invalidateQueries([HUBS_DATA_QUERY_KEY]);
    }
  });
}

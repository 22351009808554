import { ApprovalRequestStatus } from "@/interfaces";
import { PermitView } from "./PermitView";
import { useParams, useSearchParams } from "react-router-dom";
import { useApprovalRequestById } from "@/hooks";

export function PermitPrintPreview() {
  const { approvalRequestId } = useParams();
  const [permitParams] = useSearchParams();
  const version = permitParams.get("version") ?? undefined;
  const { data: approvalRequest } = useApprovalRequestById(approvalRequestId);
  return (
    <PermitView
      approvalRequestId={approvalRequestId ?? ""}
      approvalRequestStatus={approvalRequest?.approvalRequestStatus ?? ApprovalRequestStatus.DraftPermit}
      isPrintPreview={true}
      onPrintClick={() => {
        print();
      }}
      version={version}
    />
  );
}

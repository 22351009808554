import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  OutlinedInput,
  Box,
  Chip,
  useTheme
} from "@mui/material";
import { Controller } from "react-hook-form";

import { ARFormSection, IOption } from "@/interfaces";
import { withARAwareReadOnly } from "@/components/hocs";
import { AppFormMultiSelectProps } from "./AppFormProps";
import { useMemo } from "react";

export const AppFormMultiSelect = ({
  name,
  control,
  label,
  options,
  isOptional,
  onChangeHandler,
  error,
  resetError,
  initialValue,
  size,
  "data-testid": dataTestId
}: AppFormMultiSelectProps) => {
  const theme = useTheme();
  const indexedOptions = useMemo(() => {
    const optionsIndexedById: Record<string, IOption> = {};
    options.forEach((option) => {
      optionsIndexedById[option.id] = option;
    });
    return optionsIndexedById;
  }, [options]);

  const selectLabelText = isOptional ? `${label}` : `${label}*`;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      },
      dataTestId: "multi-select-menu"
    }
  };

  function getStyles(optionId: string, selectedOptions: string[]) {
    return {
      fontWeight: selectedOptions.includes(optionId)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular
    };
  }

  return (
    <FormControl fullWidth size={size}>
      <InputLabel>{selectLabelText}</InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={initialValue}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              onChange={(event) => {
                const value = event.target.value;
                // MUI Multi select returns a comma delimited string with all the selected values so we convert to string array.
                typeof value === "string" ? value.split(",") : value;
                onChange(value);
                onChangeHandler?.(value, name);
                resetError?.();
              }}
              size={size}
              multiple
              id={`select${label ? "-" + label : ""}`}
              label={selectLabelText}
              input={
                <OutlinedInput label={selectLabelText} inputProps={{ "data-testid": `multi-select-input-${name}` }} />
              }
              value={value}
              renderValue={(selectedOptions) => {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }} data-testid="multi-select-values">
                    {selectedOptions.map((selectedOption: string) => {
                      const option = indexedOptions[selectedOption];
                      return option ? (
                        <Chip key={option.id} label={option.value} data-testid={`multi-select-chip-${option.value}`} />
                      ) : null;
                    })}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
              data-testid={dataTestId}
            >
              {options.map(({ id, value: optionValue }) => (
                <MenuItem key={id} value={id} style={getStyles(id, value)}>
                  {optionValue}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
      <FormHelperText error sx={{ marginBottom: 1 }}>
        {/*Empty string is to avoid the height being manipulate when appending the error message*/}
        {error?.message ? error.message : " "}
      </FormHelperText>
    </FormControl>
  );
};

export const ARAwareReadOnlyAppFormSelect = withARAwareReadOnly(ARFormSection.BasicForm, AppFormMultiSelect);

import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "@/api/client";
import { IOption, LookUpResponse, NVCPInstrument, PermitResponse } from "@/interfaces";
import {
  RETURN_REASON_QUERY_KEY,
  SITE_LIST_QUERY_KEY,
  HUB_LIST_QUERY_KEY,
  BUSINESS_UNIT_LIST_QUERY_KEY,
  PROJECT_LIST_QUERY_KEY,
  PERMIT_QUERY_KEY,
  APPROVAL_REQUEST_TYPE_LIST_QUERY_KEY,
  INSTRUMENT_ID_LIST_QUERY_KEY,
  RESTRICTED_CLEARING_AREA_LIST_QUERY_KEY
} from "@/constants";
import { getTrackingCommentsRefData } from "@/services";

const lookupPath = "/lookup";
const permitPath = "/permit";
const referenceDataPath = "/reference";

export function useHubList() {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [HUB_LIST_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<LookUpResponse>(`${lookupPath}/hub`, {
          params: { isEnabled: true }
        })
        .then((response) => response.data.optionList),
    initialData: [],
    onSuccess: () => {
      queryClient.invalidateQueries([SITE_LIST_QUERY_KEY]);
    }
  });
}

export function useInstrumentIdList(arMapId: string) {
  return useQuery({
    queryKey: [INSTRUMENT_ID_LIST_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<NVCPInstrument[]>(`${referenceDataPath}/nvcp-instruments`, {
          params: {
            approvalRequestShapeId: arMapId
          }
        })
        .then((response) => response.data),
    initialData: []
  });
}

export function useRestrictedClearingList(referenceNo?: string) {
  return useQuery({
    queryKey: [RESTRICTED_CLEARING_AREA_LIST_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<IOption[]>(`${lookupPath}/RestrictedClearingArea`, {
          params: { referenceNo }
        })
        .then((response) => response.data),
    initialData: [],
    enabled: referenceNo !== undefined
  });
}

export function useSitesByHubId(hubId?: string) {
  return useQuery({
    queryKey: [SITE_LIST_QUERY_KEY, hubId],
    queryFn: () =>
      apiClient
        .get<LookUpResponse>(`${lookupPath}/site`, {
          params: {
            hubId,
            isEnabled: true
          }
        })
        .then((response) => response.data.optionList),
    enabled: hubId !== undefined,
    initialData: []
  });
}

export function useSiteListForHubs(hubs?: LookUpResponse) {
  return useQueries({
    queries: hubs
      ? hubs.optionList.map(({ id }) => ({
          queryKey: [SITE_LIST_QUERY_KEY, id],
          queryFn: () =>
            apiClient
              .get<LookUpResponse>(`${lookupPath}/site`, {
                params: {
                  id,
                  isEnabled: true
                }
              })
              .then((response) => response.data.optionList)
        }))
      : []
  });
}

export function useBusinessUnitList(isEnabled: boolean | undefined) {
  return useQuery({
    queryKey: [BUSINESS_UNIT_LIST_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<LookUpResponse>(`${lookupPath}/businessUnit`, {
          params: { isEnabled }
        })
        .then((response) => response.data.optionList),
    initialData: []
  });
}

export function useProjectList(isEnabled?: boolean) {
  return useQuery({
    queryKey: [PROJECT_LIST_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<LookUpResponse>(`${lookupPath}/project`, {
          params: { isEnabled }
        })
        .then((response) => response.data.optionList),
    initialData: []
  });
}

export function useReturnReasons(isEnabled?: boolean) {
  return useQuery({
    queryKey: [RETURN_REASON_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<LookUpResponse>(`${lookupPath}/returnReason`, {
          params: { isEnabled }
        })
        .then((response) => response.data.optionList),
    initialData: []
  });
}

export function useApprovalRequestTypeList(isEnabled: boolean | undefined) {
  return useQuery({
    queryKey: [APPROVAL_REQUEST_TYPE_LIST_QUERY_KEY],
    queryFn: () =>
      apiClient
        .get<IOption[]>(`${lookupPath}/approvalRequestType`, {
          params: { isEnabled }
        })
        .then((response) => response.data),
    initialData: []
  });
}

export function useApprovalRequestPermit(approvalRequestId: string, version?: string) {
  return useQuery({
    queryKey: [PERMIT_QUERY_KEY, approvalRequestId],
    queryFn: () =>
      apiClient
        .get<PermitResponse>(`${permitPath}`, {
          params: {
            approvalRequestId,
            version
          }
        })
        .then((response) => response.data)
  });
}

export function useReferenceTrackingComment(disciplineId: string) {
  return useQuery({
    queryKey: ["reference-tracking-comments", disciplineId],
    queryFn: () => getTrackingCommentsRefData(disciplineId),
    initialData: []
  });
}
